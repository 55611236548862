
@media screen and (max-width: 1100px){
    .products__icons-img{
      width: 10%;
    }
  }
  @media screen and (max-width: 900px){
    .filter__cardNew { 
        position: absolute; 
        display: flex; 
        flex-direction: column; 
        background-color: white; 
        left: 0; 
        width: 100%; 
        height: auto;
        transition: all 0.5s ease; 
        z-index: 500  ;

       
    } 
    /* .fixes__btn{
        right: 2%;
        width: 45px;
        height: 45px;
        z-index: 100;
    } */
    .fixes__btn img{
        width: 17px;
        height: 17px;
    }
    .btn__up i{
        font-size: 17px;
    }
    .container__filter{
        z-index: 100;
        width: 60%;
        margin: 5% auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;

    }
    .filter__card{
        display: none;
    }
    .products__icons-name{
        font-size: 20px;
    }
    .products__icons{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .products__icons-img{
        display: flex;
        justify-content: space-between  ;
        width: 87%;
    }
    .products-icon{
        display: flex;
        justify-content: space-between;
        width: 20%;
    }
    .filter__burger{
        display: flex; 
        cursor: pointer;
    }
  
    .products-icon img{
        width: 25px;
        height: 25px;
    }
    .products-icon {
        display: flex;
        align-items: center;
        width: 15%;

    }
    .product__card-inline{
        margin: 0 auto;
        margin-bottom: 2%;

    }
    .product__card-more,.product__card-inlineinfo2{
        display: none;
    }
    .notData{
        margin-right:0px;
        font-size: 20px;
    }
  }
@media screen and (max-width: 768px){ 
   
  
 
}

@media screen and (max-width: 630px){ 
    .products{
        margin-left: 0px;
        margin-bottom: 30px;
    }
    .product__card {
        width: 90%;
        height: auto;
        margin: 2% auto;
    }
    .products a{
        display: block;
        width: 48%;
    }

    .product__card img{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }
    .products__icons-img{
        width: 88%;
    }
    .product__card-inline img {
        width: 250px;
        height: 180px;
        border-radius: 8%;
        padding: 3px;
    }
    .product__card-inline img {
        width: 200px;
        height: 150px;}
}


@media screen and (max-width: 570px){ 


  

    .total__text {
width: 30%;
    }
    .notData{
        font-size: 18px;
    }
}

@media screen and (max-width: 520px){ 
  .products-icon{
    width: 25%;
  }
  .product__card-info3 {
    font-size: 10px;}


}

@media screen and (max-width: 470px){ 


 
    .total__area{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 2%;
    }
    .total__text {
        width: 88%;
        border-radius: 8px;
        margin-top: 2%;
    }
    .product__card-more{
        font-size: 12px;
    }
    .fixes__btn{
        width: 40px;
        height: 40px;
    }
    .btn__up {
        width: 40px;
        height: 40px;
    }
    .btn__up{
        right: 2px;
        top: 84%;
    }
    .fixes__btn{right: 2px;}
}
@media screen and (max-width: 430px){ 

    .product__card-price{
        font-size: 15px;
    }
    .product__card-info {
        font-size: 10px;
    }
    .product__card-info3,.product__card-code {
        
        font-size: 9px;

    }
    .product__card-inline img {
        width: 170px;
        height: 130px;
        padding: 0px;
    }
}
@media screen and (max-width: 368px){ 
    .product__card-inline img {
        width: 130px;
        height: 99px;
    }

 
}
@media screen and (max-width: 330px){ 
 

    .product__card img{
        width: 100%;

    }
}
@media screen and (max-width: 320px){ 
 

    .product__text-inline {
        padding-left: 5px;
    }
    .product__card-info{
        padding: 0px;
    }
}