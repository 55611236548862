.range__global {
    font-family: sans-serif;
    text-align: center;
  }
  
  
  input[type="range"] {
    -webkit-appearance: none;
    height: 7px;
    background: #F3F3F3;
    border-radius: 5px;
    background-image: linear-gradient(#287985
, #287985
);
    background-repeat: no-repeat;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #F3F3F3;
;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #287985;
    transition:  0.3s ease-in-out;
    border: 3px solid #287985;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: #287985
50 0px 0px 0px 8px;
  }
  
  input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: #287985
50 0px 0px 0px 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  