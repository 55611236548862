.info__location {
    display: flex;
    align-items: center;
    width: 100%;

}
.product-img{
    width: 100px;
    height: 100px;
}
.prod__none{
    display: none;
}
.location__img {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
}

.product__info-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product__info-global {
    width: 50%;
}

.product__info-global1 {
    font-weight: 700;
    font-size: 20px;
}

.product__info-price {

    width:100%;
}
.price__txt{
    font-size: 25px;
    margin-left: 10px;
}
.product__info-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product__info-locInf {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #7D8C92;
}

.product__info {
    width: 85%;
    margin: 0 auto;
}

.product__info4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 27px;
    color: #454545;
}

.product__info-loccal {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.prod__container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    word-break: break-word;

}

.prod__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product__start {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30%;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #7D8C92;
}

.productsStart {
    color: #7D8C92;
}

.prod {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.product__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.product__item-img {
    width: 60%;
}

.product__item-img img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.product__item-icon {
    height: auto;
    width: 37%;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
}

.product__content-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50%;
    line-height: 50px;
    margin-bottom: 2%;
}

.product__icon-item {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
    display: grid;
    place-items: center;
}

.product__icon-item img {
    width: 30px;
}

.product__icon-items {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
}

.whatsapp {
    background: #35B74D;
}

.viber {
    background: #81459B;
}

.telegram {
    background: #32A0E2;
}

.massenger {
    background: linear-gradient(136.47deg, #FF6967 6.9%, #A233FA 55.18%, #0594FF 104.47%, rgba(50, 160, 226, 0) 104.47%, #0594FF 104.47%);
}

.phone {
    background: #144A52;
}

.product__icon {
    padding: 20px;
}

.product__icon-h {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.product__icon-text {
    font-weight: 700;
    font-size: 15px;
}

.product__info-global {
    width: 50%;
}


/* .product__info-global1{
    font-weight: 700;
font-size: 25px;
color: #144A52;
} */

.product__info-price {
    font-weight: 400;
    font-size: 30px;
    color: #287985;
}

.product__info-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product__info-locInf {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #7D8C92;
}

.product__info {
    width: 100%;
}

.product__info4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 27px;
    color: #454545;
}

.product__info-loccal {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.product__loccal-info {
    width: 48%;
}

.product__loccal-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.product__loccal-item {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #7D8C92;
}

.product__loccal-type {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.local-title {
    font-weight: 700;
    font-size: 25px;
    color: #144A52;
    line-height: 40px;

}



.product__info-date {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 10px;
    color: #7D8C92;
    width: 40%;
}

.modal-product {
    width: 90%;
    background-color: black;
    padding: 10px;
    border-radius: 5px;
    border: none;
    height: 98%;
}

.modal-overlay-product {
    background-color: #000000;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 100%;

}

.swiperModal {
    background-color: #000000;
    border: 1px solid black;
    width: 650px;
    height: 650px;
}

.swiperModal img {
    width: 100%;
    height:100%;
}

.swiperModal .swiper-button-next {
    color: white !important;
}

.swiperModal .swiper-button-prev {
    color: white !important;
}

.ModalSwiper {
    background-color: #000000;
}

.modal__image-text {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    word-break: break-word;
    width: 90%;
}

.modal__header {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
}

.product__info-loccal {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.product__item-img {
    width: 60%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px;
}
.product__item-iconImg{
    display: flex;
    justify-content: space-between;
    /* max-width: 80%; */
    align-items: flex-start;
    max-width: 87%;
    padding: 10px;
    margin-top: 20px;
}
.product__iconImg{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    line-height: 10px;
    width: auto;
    height: auto; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
}
.product__item-iconImg img{
    width: 95px;
    height: 90px;
    /* height: 80px;
    width: 80px; */
    margin-top: 5%;
    background-color: #f1f1f1;
    margin: 10px;
    text-align: center;
    line-height: 75px; 
}



.product___item-block{
    display: none;
}
@media screen and (max-width: 1000px) {
    .product__content-info {

    margin-top: 5%;

}
/* .swiperModal {

    height: 500px;
} */


    .product__item-iconImg{
        display: none;
    }
    .product__item-none{
        display: none;
    }
    .product___item-block{
        display: block;
    }
    .product__item {
        display: flex;
        flex-direction: column;
    }
    .product__item-icon {
        width: 87%;
        margin: auto;
        padding-bottom: 5%;
    

    }
    .product__item-img {
        width: 87%;
        margin: auto;
    }
    .product__item-img img {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .product__start {
        width: 40%;
        margin-left: 20%;
    }
    .info__location {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
    .product__info {
        margin-top: 3%;
        width: 85%;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 10px;
    }
    .lineIcon {
        display: none;
    }
    .location__img {
        width: 15%;
    }
    .product__info-price {
        width: -webkit-fill-available;
    }
    .product__item-img {
        width: 85%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 10px;
    }
    .product__item-icon {
        margin-top: 2%;
    }
}

@media screen and (max-width: 950px) {
    .modal__image-text {
        font-size: 18px;
    }
    .product__info-location {
        display: flex;
        flex-direction: column;
        line-height: 0px;
    }
    .info__location {
        width: 115%;
        display: flex;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 850px) {
    .modal__image-text {
        font-size: 16px;
    }
    /* .swiperModal {
        height: 800px;
        width: 90%;
    } */
    .modal__image-text{
        display: none;
    }
    .modal-product{
        width: 100%;
    }
    .swiperModal img{
        width: 100%;
    }
    .swiperModal{
        height: auto;
        width: 100%;
        margin-top: 10%;
    }
    .modal__header {
        margin-top: 2%;
        width: 95%;
    }
}

@media screen and (max-width: 768px) {
    .product__start {
        width: 35%;
        margin-left: 10%;
    }
}

@media screen and (max-width: 750px) {
    .modal__image-text {
        font-size: 14px;
    }
    .product__info-global {
        width: 60%;
    }
}

@media screen and (max-width: 700px) {
    .product__start {
        width: 50%;
        margin-left: 5%;
    }
    .product__info-loccal {
        display: flex;
        flex-direction: column;
        width: 95%;
    }
    .product__loccal-info{
        width: 100%;
    }
}
@media screen and (max-width: 600px){
    /* .swiperModal {

        height: 350px;
    } */
    /* .swiperModal img {
        width: 90%;
        height: 70%;
        margin-bottom: 30%;
    } */
}

@media screen and (max-width: 570px) {
    .product__start {
        width: 60%;
        margin-left: 2%;
    }
    .product__info-global {
        width: 70%;
    }
    .swiperModal {
   
        margin-top: 40%;
    }
}
@media screen and (max-width: 500px){
    /* .swiperModal {

        height: 300px;

    } */
    .product__info-locInf{
        width: 80%;
    }
    .local-title {
        line-height: 27px;

    }
}
@media screen and (max-width: 480px) {
    .product__info-loccal {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .product__start {
        font-size: 12px
    }
    .product__info-global {
        width: 100%;
        padding: 10px;
        line-height: 0px;
    }
    .product__info-price {
        font-size: 25px;
    }
    .product__info-global1 {
        font-size: 16px;
        line-height: 20px;
    }
    .swiperModal {
        margin-top: 50%;
    }
    .local-title,.product__info-global1 {
        font-size: 20px !important;
    }
}
@media screen and (max-width: 450px){
    .prop{
        display: flex;
        flex-direction: column;
        font-size: 13px;
       
    }
    .product__loccal-item{
        font-size: 14px;

    }
    .product__loccal-type{
        font-size: 13px;
    }
}
@media screen and (max-width: 400px) {
    .product__start {
        width: 70%;
    }
    .product__icon-item {
        width: 40px;
        height: 40px;
    }
    .product__icon-item img {
        width: 20px;
    }
    .product__icon-h {
        font-size: 17px;
        text-align: center;
    }
    .product__icon-items {
        width: 250px;
    }
    .product__item-icon {
        width: 90%;
    }
}
@media screen and (max-width: 360px){
    .prop{
    
        font-size: 12px;
       
    }
    .product__loccal-item{
        font-size: 13px;

    }
    .product__loccal-type{
        font-size: 12px;
    }
}
@media screen and (max-width: 350px) {
    .product__start {
        width: 70%;
    }
}

@media screen and (max-width: 330px){
    .prop{
    
        font-size: 11px;
       
    }
    .product__loccal-item{
        font-size: 11px;

    }
    .product__loccal-type{
        font-size: 11px;
    }
}