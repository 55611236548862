/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .container {
    margin:0 auto;
    max-width:480px;
    height: 50vh;
    width: 100%;
    text-align:center;
    background:blue;
  }
  
  .modal-overlay {
    background: rgba(0, 0, 0, 0.5);  
     position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    width: 90%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 450px;
   height: 210px;
  }
  
  

  .closeModal{
  
    padding: 5px;
    cursor: pointer;
    height: 15px;
    width: 15px;
    

  }
  .modal__content-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    width: 100%;
  }

  .modal__icon-item{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
    display: grid;
    place-items: center;
}
.modal__icon-item img{
  width: 30px;
}
.modal__icon-items{
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: center;
}
.whatsapp{
    background: #35B74D;
}
.viber{
    background: #81459B;

}
.telegram{
    background: #32A0E2;

}
.massenger{
    background: linear-gradient(136.47deg, #FF6967 6.9%, #A233FA 55.18%, #0594FF 104.47%, rgba(50, 160, 226, 0) 104.47%, #0594FF 104.47%);
}
.phone{
    background: #144A52;
}

.modal__icon{
    padding: 20px;
}

.modal__icon-h{
    font-weight: 500;
font-size: 20px;
}
.modal__icon-text{
    font-weight: 700;
font-size: 15px;
}

.fixes__btn{
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 10px;
  top: 90%;
  z-index: 99;
  padding: 0;
  text-align: center;
  background: #144A52;
  border-radius: 50%;
  display: inline-block;
  display: grid;
  place-items: center;
  cursor: pointer;
  align-items: center;
}




.btn__up { position: fixed; 
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 10px;
  top: 82%;
  text-align: center;
  background: #144A52;
  border-radius: 50%;
  z-index: 100;
  color: white;
  border: none;
  cursor: pointer; 
}
.btn__up i{
  font-size: 20px;
}
#wrapper { width: 300px; background: orange; margin: 0 auto; position: relative; }

.modalArea{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}



@media screen and (max-width: 500px){ 
  
  .modal__content-info{
    margin-top: 0px;
    line-height: 30px;
  }


}