.header__active{ 
    /* position: fixed; 
    top: 0;  */
    width: 100%; 
    background-color: transparent; 
    backdrop-filter: blur(60px); 
    transition: transform 1s; 
    z-index: 3; 
}
.header__container{
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;

   
}
.unlucky__flag{
    display: flex;
}
select{
    background-color: #144A52;
    border: none;
    color: white;
    outline: none;

}
option{
    background-color: white;
    color: black;
}
.flag{
height: 10px;
width:10px;

}

.header__wrapper{ 
    display: flex; 
    align-items: center; 
    justify-content: space-between;
} 
.header__logo{ 
    /* width: 120px;  */
    width: 140px; 
}
.header__logo-img{ 
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
} 
.header__burger{
    display: none;
}
.serch__icon-none{
    display: none;
}
.header__bottom-icon{
    display: none;
}
.header__menu{ 
    display: flex; 
    justify-content: space-between; 
    position: relative; 

}
.header__menu-list{ 
    display: flex; 
    list-style-type: none; 
  
} 
.header__menuPhone{
    display: flex;
    /* justify-content: space-around; */
    width: 17%;
    align-items: center;
}
.unlucky{
    display: none;
}

.header__menu-item{ 
    margin: 0 20px; 
 
} 
.header__menu-item{ 
    margin: 0 20px; 

} 
.header_lang-none{
    display: none;
}
.header__menu-link{ 
    text-decoration: none; 
    font-family: 'Roboto', sans-serif;
    font-size: 20px; 
    line-height: 28px;
    color: light-menu-color; 
    font-style: normal; 
    font-weight: 400; 
    color: black;
 
} 
.header__menu-link:hover{ 
    text-decoration: underline; 
    /* text-underline-offset: 3px;  */
    font-weight: 500;
} 

.header__phone{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px;
}
.header__phone a{
    text-decoration: none;
    color: black;
}


 
.active{ 
    text-decoration: underline; 
    text-underline-offset: 3px; 
}
 
.header__bottom{
width: 100%;
background-color: #144A52;
display: flex;
justify-content: space-evenly;

}

.header__bottom-iconItem{
    background-color: white;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: inline-block;
    display: grid;
    place-items: center;

}
.header__bottom-icon{
    display: flex;
    justify-content: space-between ;
    width: 12%;
}
.header__bottom-iconItem:hover{
    background: #07424a;
    color: white;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.header__lang{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100px;
}

.header__logo-none{
    display: none;
}
.header__burger-none{
    display: none;
}

.serch__icon-none{
    display: none;
}

.header__phone_number{
    color: #27404A;
    padding-left: 15px;
}
.header__phone_number:hover{
    color: #27404A;
    text-shadow: 3px 3px 20px #101010, -2px 1px 30px #141314;
    transition: 1s;
}
.yesserch{
    display: none;
}
.mobilearea{
    display: none;
}
.product__card-info{
    color: black;
    padding: 2px;
    font-size: 13px;

}
.modal-header{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
height: 100%;
}

/* //////////////////////////// */
/* 
    Hover Menu
*/
.link-1 {
    transition: 0.3s ease;
    color: #ffffff;
    text-decoration: none;
    border-top: 4px solid #134a52;
    border-bottom: 4px solid #134a52;
  }
  .link-1:hover {
    border-top: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    padding: 13px 0; 
    text-decoration: none;
    color: #e5eced;
  }
