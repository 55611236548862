@media screen and (max-width: 1100px){ 
    .about__peopel-img{
        width: 180px;
        height: 180px;
        top: -100px;
        left: 75px;
    
    
    }
    .about__peopel-card{
        width: 320px;
    height: 310px;
    }
}
@media screen and (max-width: 950px){ 
    .about__peopel{
        /* display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center; */
        display: none;
    }
    .about__peopel-card{
        margin-top: 15%;
    }
.about__time-h{
    text-align: center;
}
.aboutSwiper{
    width: 600px;
    height: 600px;
    display: block;
}

.about__peopel-card{
    width: 500px;
    height: 450px;
}
.about__peopel-img{
    width: 250px !important;
    height: 250px !important;
    left: 23% !important;
}
.about__card-text{
    padding: 30px;
}

.about__peopel-iconItem{
    width: 40px;
    height: 40px;
}
.about__peopel-iconItem img{
    width: 25px !important;
    height: 25px !important;
}
}


@media screen and (max-width: 650px){ 
    .aboutSwiper{
        width: 450px;
        height: 500px;
    }
    .about__peopel-card{
        width: 400px;
        height: 350px;
    }
    .about__peopel-img{
        width: 200px !important;
        height: 200px !important;
        left: 22% !important;
    }
    .about__card-text{
        padding: 0px;
    }
    .about__info-h{
        font-size: 45px;
        width: 260px;
    }
    .about__info-text{
        font-size: 18px;
    }
    .about__time-h{
        font-size: 22px;
    }

    .about__info-h{
        font-size: 35px;
        width: 200px;
    }
  
}

@media screen and (max-width: 430px){ 
    .aboutSwiper{
        width: 350px;
        height:410px;
    }
    .about__peopel-icon{margin-top: 20px;}
    .about__peopel-card{
        width: 300px;
        height: 250px;
    }
    .about__peopel-img{
        width: 150px !important;
        height: 150px !important;
        /* left: 10% !important; */
    }
    .about__card-text{
        line-height: 9px;
        margin-top: 30%;

    }

    .about__info-text{
        font-size: 15px;
    }
    .about__peopel-iconItem{
        width: 30px;
        height: 30px;
    }
    .about__peopel-iconItem img{
        width: 15px !important;
        height: 15px !important;
    }
    .about__info-h{
        font-size: 30px;
    }
}

@media screen and (max-width: 330px){ 
    .aboutSwiper{
        width: 280px;
        height:350px;
    }
    .about__peopel-card{
        width: 280px;
        height: 250px;
    }
    .about__peopel-img{
        width: 120px !important;
        height: 120px !important;
        margin-top: 10%;
        left: 25% !important; 
    }
    .about__card-text{
       line-height: 5px;
    }
    .about__info-h{
        font-size: 35px;
    }
    .about__info-text{
        font-size: 13px;
    }

}