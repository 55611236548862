.card__input-btn {
    background: #287985;
    border-radius: 8px;
    border: none;
    margin-top: 5%;
    font-size: 15px;
    padding: 10px;
    color: white;
    width: 100%;
}

.card__input-btn:active {
    border: 1px solid white;
    border-radius: 8px;
}

.modal__contacts-info {
    padding: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
}