
.home__start{
    position: relative;
    background-color: black;
    z-index: -2;
}

/* .scroller{
  display: block;
  padding-right: 30px;
  padding-top: 2px;
  width: 100%;
  height: 1300px;
  overflow-y: scroll;
  scroll-behavior: smooth;

} */
.headerIcon__mobile{
  display: flex;
}

.home__start::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: url("../image/bgimg.png") no-repeat center center;
    background-size: cover;
    z-index: 0;
}
.content{
    position: relative;
    width: 100%;
    height: 550px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content__text1{
    font-size: 48px;
    font-weight: 400 ;
    margin-top: 5%;

  }
  .content__text2{
    font-weight: 500;
    font-size: 64px;
  }
  .content__text3{
    font-weight: 400;
    font-size: 32px;
  }

/* top */

.top__text{
    color: #144A52;
    font-weight: 500;
font-size: 32px;
}

.topprod{
    width: 78%;
}


/* filter */
.products__icons{
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.products__icons-name{
  font-weight: 500;
font-size: 32px;
line-height: 38px;
color: #144A52;
}
.products__icons-img{
  display: flex;
  justify-content: space-between;
  width: 10%;
}
.crds__area{
  line-height: 50px;
  display: flex;
  justify-content: space-between;
   margin: 0 auto;
  max-width: 1400px;
  width: 90%;

}

.filter__card{
  height: 100%;
  width:auto;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 8px;


}
.filter__cardNew{
  height: 100%;
  width:auto;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 8px;
}

.total{
  line-height: 20px;
}
.total__area{
  display: flex;
  justify-content: space-between;
  width: 280px;
  
}
.total__area-inp{
  width: 110px;
  padding: 10px 10px; 
  border: 1px solid #7D8C92;
  border-radius: 8px;
  color: #7D8C92;
}

.select__filter{
  padding: 10px 10px; 
  width: 280px;
  border: 1px solid #7D8C92;
  border-radius: 8px;
  color: #7D8C92;
}
.select__filter-element{
  color: black;
  border: 1px solid #7D8C92;
  border-radius: 8px;
  padding: 20px;
}

.main{
  width: 60%;
 
}
select{
  font-size: 15px;
  padding: 0em 0.5em;
}

/* .products{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  line-height: 10px;
  width: auto;
  
  
} */
.products{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 10px;
  width: auto;
  height: auto;
  margin-left: 20px;

  
}

.cards__areanew{
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin: 0 auto;
  line-height: 50px;
  max-width: 1400px;
  width: 90%;

}
.products__inline img{
  width: 300px;
  height: 250px;
  border-radius: 8px;
  padding: 10px;

}

.product__card-info2{
  display: none;

}
.product__card-info-title{
  display: none;
}
.product__card-inlineinfo2{
  font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #144A52;


}
.span__txt{
  background-color: red;
  color: yellow;
}

.product__text-inline{
  line-height: 20px;

}
.products,.products__inline{
  word-break: break-word;

}
 .product__card{
  width: 290px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10%;
  position: relative;
  }
  .product__card:hover{
    z-index: 1;
    -webkit-box-shadow: 0 0 17px rgb(0 0 0 / 50%);
    -moz-box-shadow: 0 0 17px rgba(0,0,0,.5);
    -o-box-shadow: 0 0 17px rgba(0,0,0,.5);
    box-shadow: 0 0 17px rgb(0 0 0 / 50%);
  }


  

  .product__card-inline {
     display: flex;
     justify-content: center;
    align-items: center;
    width: 95%;
    height: auto;
    line-height: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 2%;
    padding-left: 2%;
    margin-left: 2%;
    padding: 10px;
    position: relative;

  }
  .product__text-inline{
    padding-left: 2%;
    width: 70%;

  }

  .products__inline{
    display: flex;
    flex-direction: column;
    line-height: 10px;
    width: 100%;
    float: left;

  }
  
  .product__card img{
    width: 100%;
    height: 240px ;
    border-radius: 4%;
  }
  .product__card-inline img{
    width:300px;
    height: 270px ;
    border-radius: 8%;

  }
  .product__card-price{
    font-weight: 600;
    font-size: 20px;
    color: #287985;
    text-align: left;
line-height: 20px;
  }
  .product__card-info{
    font-size: 14px;
  line-height: 20px;
  text-align: left;
  }
  .product__card-code{
     color: #7D8C92;
    font-size: 13px;
  }
  .product__card-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product__card-textinline{
    display: flex;
    flex-direction: column;
    line-height: 0px;

  }
  .product__card-info3{
    font-weight: 400;
font-size: 13px;
line-height: 0px;
color: #7D8C92
;
width: max-content;

  }

  .product__card-more{
    color: #144A52;
    font-size: 14px;
    text-decoration: underline;
  }
  

.urgent{
  background: #D8423A;
  border-radius: 10px 0px;
  width: 75px;
  height: 30px;
  position: absolute;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 25px;
  padding: 10px;
  font-weight: 500;

}

.paginational__area{
  float: right;
}
.paginational__conteiner{
  max-width: 70%;
  margin: 0 auto;
}
.nofilterres{
  display: none;
}

