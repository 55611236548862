
   
.header__search-inp{ 
    background-color: transparent; 
    border: none; 
    outline: none; 
    font-weight: 500; 
    font-size: 18px; 
    transition: 0.8s; 
    text-indent: 20px; 
    color: black; 
    width: 250px; 
    cursor: auto; 
} 


.searchInputs {
    display: flex;
    position: relative;
  }
  
  .search input {

    border-radius: 30px; 
    padding: 10px 8px; 
    display: flex; 
    align-items: center; 
    transition: 0.8s; 
    cursor: pointer; 
    border: 1px solid #144A52 ;
    border-radius: 30px;
    width: 100%;
  }

  .header__search-img-none{
    position: absolute;
    padding: 10px;
    /* top: 18%; */
    top: 21%;
    width: 15px;
  }
  .header__search-img{
    position:absolute;
    left: 9%;
    bottom: 37%;
  
    

  }
   .serchInput__active {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .serch_img{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin-top: 2%;
    padding: 10px;
  }

  

  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 363px;
    height: 300px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index:10000;
    border-radius: 8px;
  }

  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  .dataItem{
    display: flex;
    justify-content: space-between;
  }

  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  .serch_txt{
    color: black;
    margin-left: 10px;
  }
  .serch__item:hover {
      background-color: lightgrey;
    }

  #clearBtn {
    cursor: pointer;
  }

  .serchInput__active {
    width: 100%;
    padding: 10px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .serch__item{
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between;
  }
  .search input{
    width: 350px;
  }

@media screen and (max-width: 770px){ 
  .dataResult{
    width: 400px;
  }
}


@media screen and (max-width: 500px){ 
  .dataResult{
    width: 300px;
  }
}
@media screen and (max-width: 420px){
  .dataResult {
    width: 250px;
}
.header__search-img {
  left:11%;
}
.closer {
 
  margin-top: 6%;
}
}