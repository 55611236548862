footer{
    background-color: #144A52;
    margin-top: 5%;
    height: 150px;
    
}

.footer__menu-list{
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    margin:auto;
    border-bottom: 1px solid #E7E7E7;
    padding: 20px;


}
.footer__menu-item{
    list-style: none;
}
.footer__menu-link{
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 400;
font-size: 15px;
}

.footer__end{
    font-weight: 400;
    font-size: 12px;
    color: #E7E7E7;
    text-align: center;
    margin-bottom: 5%;
}
.webSoft{
    color: white;
    text-align: center;
    margin:1% auto;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

@media screen and (max-width: 768px){ 
    
    .footer__menu-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       width: 50%;
    }
}
@media screen and (max-width: 400px){ 
    
    .footer__menu-list{
       font-size: 12px;
    }
    .webSoft{
        font-size: 12px;
    }
    .footer__end{
        font-size: 10px;}
}