.contact__container{
    display: flex;
    align-items: center;  
}
.card__icon{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
border-radius: 8px;
width: 30%;
height: auto;
}
iframe{
    width:100%;
    height:390px; 
}
.card__map{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 60%;
height: auto;
}
.contactpage{

    margin-top: 2%;
}
.card__icon-item{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    display: grid;
    place-items: center;
}
.card__icon-items{
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto;
}
.whatsapp{
    background: #35B74D;
}
.viber{
    background: #81459B;

}
.telegram{
    background: #32A0E2;

}
.massenger{
    background: linear-gradient(136.47deg, #FF6967 6.9%, #A233FA 55.18%, #0594FF 104.47%, rgba(50, 160, 226, 0) 104.47%, #0594FF 104.47%);
}
.phone{
    background: #144A52;
}

.card__icon{
    padding: 20px;
}

.card__icon-h{
    font-weight: 500;
font-size: 20px;
text-align: center;
}
.card__icon-text{
    font-weight: 700;
font-size: 15px;
text-align: center;
}

.card__icon-input{
    display: flex;
    flex-direction: column;
}
.card__input-item{
border-radius: 8px;
    line-height: 30px;
    margin-top: 2%;
    border: 1px solid #7D8C92;
    padding: 5px;
}
.card__input-textarea{
    border: 1px solid #7D8C92;
    border-radius: 8px;
    margin-top: 2%;
    padding: 10px;
    height: 70px;

}


.card__map-img img{
    width:100%;
    height: 389px;
}
.card__map{
    padding: 25px;     

}

.card__map-item{
    display: flex;
    align-items: center;
    line-height: 25px;
    
}
.card__map-item a{
    text-decoration: none;
    padding: 5px;
    color: #144A52;
    font-size: 15px;
}
.errMess{
    color: red;
    line-height: 0px;
}
@media screen and (max-width: 1100px){ 
    .card__map{
        width: 48%;
    }
    .card__icon{
        width: 38%;
    }
}
@media screen and (max-width: 1000px){ 
    .card__map{
        width: 42%;
    }
    .card__icon{
        width: 42%;
    }
}
@media screen and (max-width: 860px){ 
    .contact__container{
        display: flex;
        flex-direction: column;   
    }
    .card__icon{
        width: 80%;
    }
    .card__map{
        width: 80%;
        margin-top: 5%;
    }
}

@media screen and (max-width: 500px){ 
    .card__icon{
        width: 65%;
        
    }
    .card__map{
        width: 70%;
        padding: 10px;
    }
    .card__map-img img {
        width: 100%;
        height: 200px;
    }
    iframe{
        height:300px; 
    }
    .errMess{
    font-size: 10px;
    }

}
@media screen and (max-width: 450px){ 
    .card__icon{
        padding: 30px;
        width: 60%;

    }

    .card__icon-h {
        font-size: 15px;
    }
}
@media screen and (max-width: 400px){ 
    .card__icon-h{
        font-size: 14px;
    }
    .card__icon-items{
        width: 220px;
    }
    .card__icon-item{
        width: 35px;
        height: 35px;
    }
    .card__icon-item img{
        width: 20px;
        height: 20px;
    }
    .card__icon-text {
        font-size: 13px;
    }
    .card__icon-text {
        line-height: 25px;
    }
    .errMess{
        font-size: 12px;
    }
}

@media screen and (max-width: 330px){ 
    .card__icon-h {
        font-size: 12px;
    }
    .card__icon-items {
        width: 180px;
    }
    .card__icon-item{
        width: 25px;
        height: 25px;
    }
    .card__icon-item img{
        width: 15px;
        height: 15px;
    }
}

textarea
{
  border:1px solid #999999;
  width:100%;
  margin:5px 0;
  padding:3px;
  max-width: 93%;
}