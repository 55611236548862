
/* @media screen and (max-width: 1150px){ 
    .header__bottom-icon{
        width: 15%;
    }    

} */
@media screen and (max-width: 1100px){ 
    .header__menu-item{
        margin: 0 10px;     
       
    }
    .underlineHeader{
        width:  550px;
    }
    .header__menuPhone{
        width: 20%;
        justify-content: space-evenly;
    }

}
@media screen and (max-width: 1010px){ 
    .header__bottom-icon {
        width: 13%;
    }


}


@media screen and (max-width: 960px){
    .header__bottom-iconItem {
        width: 25px;
        height: 25px;
        margin-left: 5px;
    }
    .header__bottom-iconItem img{
        width: 20px;
        height: 20px;
    }
    .header__phone-img{
        width: 20px;
        height: 20px;
    }
}
@media screen and (max-width: 840px){
    .header__menuPhone {
        width: 25%;
    }
    .link-1{border: none;}
}


@media screen and (max-width: 768px){ 
    select{  background: #64afac;}
    .nomobile{
        height:100vh;
        object-fit: contain;
      }
    .header__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .header__active{
       position: relative; 
         width: 100%;  
    }
    .header__container{
        width: 100%; 
        margin: auto;
        

    }
    .nomobile{
        display: none;
    }
  .mobilearea{
    display: block;
  }
 .header__menuPhone{
    display: none;
 }
   
.header__menu-link{
    /* color: black; */
    color: white;
}
    .header__burger{
        display: flex; 
        cursor: pointer; 
        height: 15px;
        width: 15px;
        /* margin-top: 3%; */
    }
    .header__search-img{

        left: 7%;
    bottom: 37%;
    height: 15px;
    width: 15px;
    }
    .search input {
        font-size: 15px;
    }
    .serch__icon{
        display: flex; 
        cursor: pointer;     }
    .header__bottom-iconNon{
        display: none;
    }
    .header__menu{
        display: none; 
    }
    .header__bottom-icon{
        display: none;
    }
    .header__lang{
        display: none;
    }
   
    .header__menu-active{ 
        position: absolute; 
        display: flex; 
        height: auto;
        width: -webkit-fill-available;
        flex-direction: column; 
        /* background-color: white;  */
        background: #134a52;
        top: 85px; 
        left: 0; 
        transition: all 0.5s ease; 
        line-height: 40px;
        padding: 20px;
        font-size: 20px;
       

 
  
    } 

    .header__logoNone{
        display: none;
    }
    .header__menu-activenon{
        display: none;
    }
    .header__menu-list{
        position: absolute; 
        width: 40%; 
        display: flex; 
        flex-direction: column; 
    }
    
    .unlucky-active{
        margin-top: 25%;
        display: block;
        display: flex; 
        flex-direction: column; 
         padding: 50px;
       

    }

    .header__menu-link{
        text-align: left; 
        font-weight: 500;  
        font-size: 16px; 
        text-transform: capitalize; 
    }
 
    .unlucky-active p{
        color: black;
        font-weight: 500;
        font-size: 16px; 
        width: 100%;


    }
    .unlucky__flag{
        display: flex;
    }
    .unlucky__phone{
        display: flex;
        align-items: center;

    }
    .serch__none{
        display: none;

    }
    .serchInput__active {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .flags{
            /* background-color: white; */
    width: 31%;
    color: white;
    font-size: 16px;
    /* font-weight: 700; */
    /* margin-top: 25px; */
    border-radius: 5px;

    }
    .unlucky__flag{
        font-size: 10px;

    }
} 
@media screen and (max-width: 700px){
    .underlineHeader{
        width:  500px;
    }
}
@media screen and (max-width: 620px){
    .underlineHeader{
        width:  400px;
    }
}
@media screen and (max-width: 520px){ 

    .modal-content {
        width: 400px;
        height: 200px;
    }
    .underlineHeader{
        width:  300px;
    }
    .link-1{border: none;}
}    
@media screen and (max-width: 500px){ 

.serchInput__active {
    width: 30%;
}
.footer__menu-list{
    padding: 0px;
    line-height: 15px;
    margin-bottom: 15px;
}.footer__menu-link{
    font-size: 14px;
}
.footer__menu-item {
    margin-top: 3%;
    margin-bottom: 3%;
}


.search input{
width: 250px !important;
}
.modal-content {
    width: 250px;
    height: 140px;
    margin-bottom: 20px;

}
.modal__icon-item {
    width: 30px;
    height: 30px;
}
.modal__icon-item img{
    width:15px;
    height: 15px;
}
.closeModal{
    height: 10px;
    width: 10px;
}
.modal__icon-h {
    font-size: 13px;
}
.header__search-img { left: 9%;}
.link-1{border: none;}
.unlucky-active{
    margin-top: 30%;
    
   

}
}



@media screen and (max-width: 430px){ 
    .header__menu-active{ 
        line-height: 30px;
        padding: 5px;
       
    }  
      .unlucky-active{
        margin-top: 115px;
        border-bottom: 6px solid #134a52;
        padding: 40px;
    }
    .link-1{border: none;}
    .top__text {font-size: 20px;}
    .flags{margin-top: 20px;}
    .link-1{border: none;}

 
}   
@media screen and (max-width: 425px){ 
    .header__search-img{
        height: 13px;
        width: 13px;
      }
      .serchInput__active {height: 30px;}
      .closer {
        width: 12px;
        height: 12px;
        margin-top: 3%;
    }
    .search input {
        font-size: 12px;
    }

    .header__menu-item{
        margin: 5px 0;  
    }
    .topSwiper {
        
        z-index: 0;

    }
    .fixes__btn{
        z-index: auto;
    }

    .header__menu-link{
        font-family: unset;  
        font-style: normal; 
        font-weight: bold; 
        /* font-weight: 500;   */
        font-size: 16px; 
        line-height: 29px; 
        text-transform: capitalize; 
    }
    .unlucky-active p {
        font-size: 14px; 

    }
   
   
      /* .header__menu-active{ 
        line-height: 20px;
          
    }   */
    .search input{
        width: 200px !important;
        }
        .header__search-img {
            left: 12%;
        }
        .underlineHeader{
            width:  250px;
        }
        .link-1{border: none;}
} 


 
