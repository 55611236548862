 .swiper-slide {
     text-align: center;
     font-size: 18px;
     background: #fff;
     /* Center slide text vertically */
     display: -webkit-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-box-align: center;
     -ms-flex-align: center;
     -webkit-align-items: center;
     align-items: center;
 }
 
 .swiper-slide img {
     display: block;
     width: 80%;
     height: 100%;
     object-fit: cover;
 }
 
 .topSwiper {
     width: 95%;
     height: 100%;
     padding: 10px;
     
 }
 
 .swiper__card {
     width: 100%;
     height: auto;
     background-color: red;
     background: #FFFFFF;
     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
     border-radius: 8px;
     padding: 10px;
     word-break: break-word;

 }
 .swiper__card:hover{
    z-index: 1;
    -o-box-shadow: 0 0 17px rgba(0,0,0,.5);
    box-shadow: 0 0 17px rgb(0 0 0 / 50%);
 }
 .swiper__card img {
     width: 300px;
     height: 180px;
     border-radius: 10px;
 }
 
 .swiper__card-price {
     font-weight: 600;
     font-size: 20px;
     color: #287985;
     text-align: left;
 }
 
 .swiper__card-info {
     font-size: 14px;
     line-height: 20px;
     text-align: left;
 }
 
 .swiper__card-code {
     color: #7D8C92;
     font-size: 13px;
 }
 
 .swiper__card-text {
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 
 .swiper__card-more {
     color: #144A52;
     font-size: 14px;
     text-decoration: underline;
 }
 
 .swiper__container {
     width: 100%;
     max-width: 1500px;
     margin: 0 auto;
 }
 
 .swiper__card-info {
     color: black;
     font-weight: 700;
     color: #287985;

 }
 
 .swiper-button-next,
 .swiper-button-prev {
     color: black !important;
     width: 5px;
 }
 
 .swiper-button-next:after,
 .swiper-button-prev:after {
     font-size: 20px;
     font-weight: 700;
 }

 
 @media screen and (min-width: 1200px) {
     .swiper-container {
         width: 1200px;
     }
 }
 
 @media screen and (max-width: 1100px) {
     .topSwiper {
         width: 90%;
     }
 }
 
 @media screen and (min-width: 930px) {
     .swiper-container {
         width: 930px;
     }
 }
 
 @media screen and (min-width: 700px) {
     .swiper-container {
         width: 700px;
     }
 }
 
 @media screen and (min-width: 500px) {
     .swiper-container {
         width: 500px;
     }
        .urgent {
            width: 55px;
            height: 20px;
            font-size: 13px;
        }
    
 }
 
 @media screen and (min-width: 300px) {
     .swiper-container {
         width: 300px;
     }
     .swiper__card {
         width: 95%;
         height: auto;
     }
     .swiper__card img {
         width: 100%;
         height: 160px;
         margin: 0 auto;
     }
 }
 
 .swiper-container {
     width: 1200px;
 }
 
 @media screen and (min-width: 800px) {
     .swiper-container {
         width: 640px;
     }
     .swiper__card {
         width: 95%;
         height: auto;
     }
     .swiper__card img {
         width: 100%;
         height: 160px;
     }
 }
 
 @media screen and (min-width: 640px) {
     .swiper-container {
         width: 640px;
     }
     .swiper__card {
         width: 95%;
         height: auto;
     }
     .swiper__card img {
         width: 100%;
         height: 160px;
     }
 }
 
 @media screen and (min-width: 768px) {
     .swiper-container {
         width: 768px;
     }
     .swiper__card {
         width: 95%;
         height: auto;
     }
     .swiper__card img {
         width: 100%;
         height: 160px;
     }
 }