.container__filter {
    padding: 20px;
    cursor: pointer;
}

.filter__block {
    margin-top: 3%;
}

.question {
    width: 100%;
    border: 1px solid #7D8C92;
    border-radius: 8px;
    height: 50px;
}

.filter__block {
    line-height: 50px;
}

.question__global {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 50px;
    font-size: 15px;
}

.question__global img {
    width: 15px;
    height: 15px;
    align-items: center;
}

.question__imgClose,
.rent__blockClose {
    display: none;
}

.question__img {
    display: none;
}
/* .container__filter:hover{
    color: yellow;
    background-color: red;
} */
.question__text {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #7D8C92;
}

.answers {
    border: 1px solid #7D8C92;
    border-radius: 8px;
    margin-top: 2%;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    text-align: center;
}

.answer__info {
    width: 80%;
    margin: 0 auto;
    line-height: 10px;
}

.rent__block {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.rent__block {
    display: flex;
    flex-direction: column;
}

.rents {
    display: flex;
    justify-content: space-between;
}

.answer-txt {
    border-bottom: 1px solid #7D8C92;
    width: 80%;
    line-height: 30px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
}

.region,
.typeFilter,
.repairFilter {
    width: 100%;
    border: 1px solid #7D8C92;
    border-radius: 8px;
    margin-top: 2%;
}

.region__name,
.typeFilter__name,
.repairFilter__name {
    width: 80%;
    margin: 0 auto;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.filter__price-text {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}

.rooms,
.toilets {
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}

.total__area {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
}

.total__text {
    border: 1px solid #7D8C92;
    border-radius: 8px;
    width: 35%;
    padding: 15px;
}

.total-txt {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.chekbox__input {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.underline {
    width: 90%;
    border-bottom: 1px solid #E7E7E7;
    margin: 0 auto;
    margin-top: 2%;
}

.chekbox__input-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.building__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

label {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}

.filter__btn {
    width: 100%;
    background-color: #287985;
    color: white;
    border-radius: 8px;
    border: none;
    padding: 10px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    margin-top: 15px;
}

.filter__btn:active {
    border: 2px solid white;
    border-radius: 8px;
}

.filter__end {
    /* padding-bottom: 5%; */
    margin-top: 4%;
}

.filed {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.filed input {
    -webkit-appearance: none;
    height: 3px;
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    outline: none;
    border: none;
}

.filed .value {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
}

.filed .value.left {
    left: -22px;
}

.filed .value.right {
    right: -43px;
}

.sliderValue {
    position: relative;
    width: 100%;
}

.sliderValue span {
    position: absolute;
    height: 45px;
    width: 45px;
    top: -40px;
    transform: translateX(-50%);
    line-height: 55px;
    z-index: 2;
}

.sliderValue span::after {
    position: absolute;
    content: "";
    height: 45px;
    width: 45px;
    background-color: green;
    transform: translateX(-50%)rotate(45deg);
    border: 3px solid white;
    z-index: -1;
    left: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.filed input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-color: blue;
    border-radius: 50%;
    border: 1px solid white;
    cursor: pointer;
}

.filter__slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border: 1px solid black;
}

.item-label {
    font-weight: bold;
    margin-right: 8px;
}

.custom-select {
    position: relative;
    font-family: Arial;
}

.see {
    display: none;
}

.noCommercion {
    pointer-events: none;
    cursor: inherit;
    margin-top: 2%;
}

.not-allowed {
    cursor: not-allowed;
    pointer-events: none;
    margin-top: 2%;
}

.noOpen {
    display: none;
    /* background-color: red; */
}


/* .commercia{
    display: block;   
} */

.filter__burger {
    display: none;
}

.filterB {
    display: flex;
}

.products-icon {
    display: flex;
    justify-content: space-between;
    width: 60%;
}

.rooms,
.toilets {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.rooms input {
    width: 80%;
}

.toilets input {
    width: 80%;
}

.products__icons-img {
    cursor: pointer;
}

.icons__question {
    display: flex;
    justify-content: space-between;
}

.close__quesition {
    height: 10px;
    width: 10px;
    padding: 5px;
}

.closer {
    display: none;
}
.notData{
    text-align: center;
    margin-right: 250px;
    font-size: 20px;
    font-weight: 700;
}