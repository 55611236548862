.about__containers {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

.about__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about__container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 5%;
}

.about__start {
    width: 100%;
}

.about__start img {
    width: 100%;
}

.about__info-h {
    font-weight: 500;
    font-size: 54px;
    line-height: 30px;
    border-bottom: 4px solid #287985;
    margin: 0 auto;
    padding: 30px;
    width: 350px;
    text-align: center;
}

.about__peopel-info {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
}

.about__info-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-top: 2%;
}

.about__time-h {
    text-align: left;
    font-weight: 500;
    font-size: 25px;
}

.about__time {
    width: 90%;
    margin: 0 auto;
}

.about__peopel {
    display: flex;
    justify-content: space-evenly;
    margin-top: 12%;
}

.about__peopel-iconItem {
    background-color: #144A52;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    display: grid;
    place-items: center;
}
.about__peopel-iconItem:hover{
    background: #298693;
    transition: all .4s ease-in-out;
}
.about__card-text{
    text-align: center;
    margin-top: 37%;
}
.about__peopel-icon {
    display: flex;
    justify-content: space-evenly;
    width: 200px;
    margin: 0 auto;
    margin-top: 35px;
}

.about__peopel-img {
    margin: 0 auto;
    border: 7px solid #144A52;
    border-radius: 50%;
    position: absolute;
    width: 200px;
    height: 200px;
    top: -100px;
    left: 85px;
}

.about__peopel-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 380px;
    height: 340px;
    position: relative;
}


.about__peopel-name {
    font-weight: 700;
    font-size: 22px;
    color: #144A52;
}

.about__peopel-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
}

.aboutSwiper {
    display: none;
}

.about__peopel-card:hover {
    /* transform: translate(0.5rem, -1.5rem); */
    transform: translateY(-5px);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
}