
@media screen and (max-width: 768px){ 
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .content__text1{
        font-size: 38px;
      }
      .content__text2{
        font-size: 33px;
      }
      .content__text3{
        font-size: 18px;
        width: 80%;      }
        
}

@media screen and (max-width: 630px){ 

    .content__text1{
        font-size: 21px;
      }
      .content__text2{
        font-size: 30px;
      }
      .content__text3{
        font-size: 18px;
      }


}

@media screen and (max-width: 750px){

  .product__card-inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
border-radius: 8px;
padding: 10px;
margin-top: 2%;
width: 100%;
  }

}
@media screen and (max-width: 530px){ 

}
@media screen and (max-width: 500px){ 
  .page{
    display: flex;
    justify-content: center;
  }
  .pagination-container {
    width: 31%;
}
    .content{
        height: 400px;
    }
    .content__text2{
        font-size: 34px;
      }
      .product__text-inline{
        line-height: 0px;
    padding-left: 14px;
      }
      .product__card-more,.product__card-inlineinfo2{
        display: none;
      }
      .product__card-inline{
        width: 90%;
            margin: 2% auto;

      }
      .product__card-inline img {
        width: 160px;
        height: 155px;
    }
    .urgent {
      width: 55px;
      height: 20px;
      font-size: 13px;
   
  }

  
}
@media screen and (max-width: 450px){ 
    .content{
        height: 370px;
    }

  .product__card-inlineinfo2 {
    font-weight: 400;
    font-size: 15px;
}
.product__card-price {
  font-weight: 500;
  font-size: 17px;
}
}
@media screen and (max-width: 400px){ 
    .content{
        height: 300px;
    }
    .content__text2{
        font-size: 23px;
      }


}

@media screen and (max-width: 380px){ 



}
@media screen and (max-width: 350px){ 
    .content{
        height: 280px;
    }

}

